<template>
  <ion-page>
    <ion-header mode="ios">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            default-href="#"
            class="back-btn"
            text=""
            @click="closePage"
            :icon="chevronBackOutline"
          >
          </ion-back-button>
        </ion-buttons>
        <ion-label class="head-title ml-2">{{ $t('invoice_cn.credit_note') }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <ion-content class="bg-content">
      <div class="bg-content">
        <div class="column bg-white">
          <ion-text color="primary" class="head-title mb-2">
            {{ invoiceDetail.supplier_name }}
          </ion-text>
          <ion-text class="my-1 bold"> #{{ creditNoteDetail?.credit_note_number }} </ion-text>
          <ion-text class="text-grey">
            {{ formatDayDate(creditNoteDetail?.credit_note_date) }}
          </ion-text>
          <ion-text color="primary" class="mt-3 mb-2 bold">
            {{ $t('invoice_cn.cn_amount') }}
          </ion-text>
          <ion-text color="primary" class="mb-3 bold price_inv">
            {{ currencySymbol + creditNoteDetail?.amount.toFixed(2) }}
          </ion-text>
          <div class="column bg-content ion-padding">
            <ion-text color="primary" class="bold">{{ $t('invoice_cn.bill_to') }}:</ion-text>
            <ion-text class="mt-2 mb-1">{{ invoiceDetail.billing_name }}</ion-text>
            <ion-text>{{ invoiceDetail.billing_address }}</ion-text>
          </div>
        </div>
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <div class="column mx-2">
          <ion-text class="my-2">{{ $t('invoice_cn.view_document') }}</ion-text>
          <ion-button @click="handleDownloadCreditNote" class="invoice-btn mb-2" color="primary">
            {{ $t('invoice_cn.cn') }} (PDF)</ion-button
          >
        </div>
      </ion-toolbar>
    </ion-footer>
    <ion-modal :is-open="isShowDocPreview">
      <pdf-preview :pdfUrls="[pdfUrl]" @on-dismiss="setShowDocPreview(false)"></pdf-preview>
    </ion-modal>
  </ion-page>
  <ion-loading
    :is-open="isDownloadCreditNote"
    @didDismiss="setOpenDownload(false)"
    cssClass="custom-loading"
    message=""
    spinner="crescent"
  >
  </ion-loading>
</template>

<script>
import { apolloClient } from '@/main';
import { downloadCreditNotePdf } from '@/modules/sale/services/graphql';
import PdfPreview from '@/modules/shared/components/PdfPreview';
import { toastError } from '@/modules/shared/utils/';
import { useDateFormatter } from '@/usecases/global';
import { Capacitor } from '@capacitor/core';
import { checkmarkOutline, chevronBackOutline, closeOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  components: {
    PdfPreview
  },
  emits: ['close-page'],
  props: {
    detailOrderId: {
      type: Number,
      default: 0
    },
    invoiceDetail: {
      type: Object,
      default: () => ({})
    },
    invoiceId: {
      type: Number,
      default: 0
    },
    creditNoteDetail: {
      type: Object,
      default: () => ({})
    },
    currencySymbol: {
      type: String,
      default: ''
    }
  },
  setup() {
    const { formatDayDate } = useDateFormatter();
    const isDownloadCreditNote = ref(false);
    const pdfUrl = ref('');
    const isShowDocPreview = ref(false);
    const setOpenDownload = (state) => (isDownloadCreditNote.value = state);
    const setShowDocPreview = (state) => (isShowDocPreview.value = state);
    return {
      formatDayDate,
      chevronBackOutline,
      checkmarkOutline,
      closeOutline,
      isDownloadCreditNote,
      pdfUrl,
      setOpenDownload,
      isShowDocPreview,
      setShowDocPreview
    };
  },
  methods: {
    closePage() {
      this.$emit('close-page');
    },

    async handleDownloadCreditNote() {
      this.setOpenDownload(true);
      try {
        const { data } = await apolloClient.mutate({
          mutation: downloadCreditNotePdf,
          variables: {
            creditNoteId: this.creditNoteDetail.id
          }
        });
        this.pdfUrl = data.downloadCreditNotePdf;
        if (!this.pdfUrl) {
          toastError(this.$t('invoice_cn.download_file_fail'));
          return;
        }
        if (Capacitor.isNativePlatform()) {
          this.setShowDocPreview(true);
        } else {
          await window.open(data.downloadCreditNotePdf, '_blank');
        }
      } catch (e) {
        toastError(this.$t('invoice_cn.download_file_fail'));
        console.log(e);
      } finally {
        this.setOpenDownload(false);
      }
    }
  }
});
</script>
<style src="./style.scss" lang="scss" scoped></style>
